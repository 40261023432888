import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Styles/MSDetail.css';
import { ScriptApi } from '../../API';

const MSDetail = () => {
  const codeUrl = process.env.REACT_APP_CODEURL;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const scriptData = useSelector((state) => state.scriptData);
  const [, setScriptId] = useState('');
  const [scriptCode, setScriptCode] = useState('');
  const [scriptPopUp, setScriptPopUp] = useState(false);
  const [addSitePopUp, setAddSitePopUp] = useState(false);
  const [delSitePopUp, setDelSitePopUp] = useState(false);
  const [siteTitle, setSiteTitle] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [feedback, setFeedback] = useState('');
  const scriptPopUpHandler = (e) => {
    if (scriptPopUp === false) {
      setScriptCode(scriptData[e.target.value].script_code);
    }
    setFeedback('');
    setScriptPopUp(!scriptPopUp);
  };
  const addSitePopUpHandler = (e) => {
    setFeedback('');
    setAddSitePopUp(!addSitePopUp);
  };
  const delSitePopUpHandler = (e) => {
    if (delSitePopUp === false) {
      setScriptId(scriptData[e.target.value].script_id);
      setScriptCode(scriptData[e.target.value].script_code);
      setSiteTitle(scriptData[e.target.value].domain);
      setSiteUrl(scriptData[e.target.value].url);
    }
    setFeedback('');
    setDelSitePopUp(!delSitePopUp);
  };
  const blockBubbling = (event) => {
    event.stopPropagation();
  };
  const inputSiteTitleHandler = (e) => {
    setSiteTitle(e.target.value);
  };
  const inputSiteUrlHandler = (e) => {
    setSiteUrl(e.target.value);
  };

  const copyScript = () => {
    const scriptText = document.getElementById('siteScriptTxt').value;

    // 클립보드에 복사하기 위해 textarea 엘리먼트를 동적으로 생성합니다.
    const textArea = document.createElement('textarea');
    textArea.value = scriptText;

    // textarea를 body에 추가한 후 포커스를 줍니다.
    document.body.appendChild(textArea);
    textArea.select();

    // 복사 명령을 실행합니다.
    document.execCommand('copy');

    // textarea를 제거합니다.
    document.body.removeChild(textArea);

    // 복사가 완료되었다는 알림을 띄웁니다.
    window.alert('스크립트가 복사되었습니다.');
  };
  const createScript = async () => {
    try {
      const { status } = await ScriptApi.createScript(
        userData.user_id,
        [userData?.email],
        siteTitle,
        siteUrl
      );
      if (status === 200) {
        const data = await ScriptApi.getScripts(
          userData?.type,
          userData?.user_id
        );
        dispatch({
          type: 'SET_SCRIPT_DATA',
          payload: data,
        });
        setAddSitePopUp(false);
      } else if (status === 204) {
        setFeedback('이미 등록된 사이트입니다.');
      } else if (status === 205) {
        setFeedback('최대 사이트 등록 수를 초과하였습니다.');
      } else {
        setFeedback('정의되지 않은 오류입니다.\n다시 시도해 주세요..');
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const deleteScript = async () => {
    try {
      const { status } = await ScriptApi.deleteScript(scriptCode);
      if (status === 200) {
        const data = await ScriptApi.getScripts(
          userData?.type,
          userData?.user_id
        );
        dispatch({
          type: 'SET_SCRIPT_DATA',
          payload: data,
        });
        setDelSitePopUp(false);
        window.alert('삭제를 성공적으로 완료하였습니다.');
      } else {
        window.alert('삭제를 성공적으로 완료할 수 없습니다.');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div id="msDetail">
        <div className="compHeader">
          <div className="title">사이트관리</div>
        </div>
        <div className="compBody">
          {scriptData?.length === 0 ? (
            <div className="noData">
              <svg viewBox="0 0 24 24" width="20" height="20">
                <path d="m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-8,6c0-.553.447-1,1-1s1,.447,1,1v7.5c0,.553-.447,1-1,1s-1-.447-1-1v-7.5Zm1,13c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Z" />
              </svg>
              데이터가 없습니다.
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>사이트코드</th>
                  <th>사이트명</th>
                  <th>사이트URL</th>
                  {/* <th>통계보기</th> */}
                  <th>스크립트</th>
                  <th>삭제하기</th>
                </tr>
              </thead>
              <tbody>
                {scriptData?.map((item, idx) => (
                  <tr className="item" key={`${idx}${item.domain}`}>
                    <td className="id">{item.script_code}</td>
                    <td>{item.domain}</td>
                    <td>{item.url}</td>
                    {/* <td>
                      <button className='tdBtn'>보기</button>
                    </td> */}
                    <td>
                      <button
                        className="tdBtn"
                        onClick={scriptPopUpHandler}
                        value={idx}
                      >
                        보기
                      </button>
                    </td>
                    <td>
                      {/* <button className="tdBtn">
                                                수정
                                            </button> */}
                      <button
                        className="tdBtn"
                        value={idx}
                        onClick={delSitePopUpHandler}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="fullBtn_container">
          <button className="fullBtn" onClick={addSitePopUpHandler}>
            사이트 등록
          </button>
        </div>
      </div>

      {scriptPopUp ? (
        <div id="script" onClick={scriptPopUpHandler}>
          <div className="content" onClick={blockBubbling}>
            <div className="close" onClick={scriptPopUpHandler}>
              <svg viewBox="0 0 24 24" width="32" height="32">
                <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
              </svg>
            </div>
            <div className="title">분석스크립트</div>
            <div className="info">
              <svg viewBox="0 0 24 24" width="12" height="12">
                <path d="M12,24A12,12,0,1,0,0,12,12.013,12.013,0,0,0,12,24ZM12,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12,5Zm-1,5h1a2,2,0,0,1,2,2v6a1,1,0,0,1-2,0V12H11a1,1,0,0,1,0-2Z" />
              </svg>
              html 최상단(head부분)에 추가해 주세요.
            </div>
            <textarea
              id="siteScriptTxt"
              className="text"
              rows={2}
              readOnly
              value={`<script src=${codeUrl}></script>\n<script>postClientData("${scriptCode}")</script>`}
            ></textarea>
            <div className="func">
              <button onClick={copyScript}>스크립트 복사</button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {addSitePopUp ? (
        <div id="addSite" onClick={addSitePopUpHandler}>
          <div className="content" onClick={blockBubbling}>
            <div className="close" onClick={addSitePopUpHandler}>
              <svg viewBox="0 0 24 24" width="32" height="32">
                <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
              </svg>
            </div>
            <h1 className="title">사이트 등록</h1>
            {feedback !== '' ? <p className="error">{feedback}</p> : <></>}

            <h3 className="subTitle">사이트명</h3>
            <div className="inputWrap">
              <input
                id="signInUserAccount"
                name="user_account"
                type="text"
                placeholder="사이트명을 입력해주세요."
                onChange={inputSiteTitleHandler}
              ></input>
            </div>
            <h3 className="subTitle">사이트 주소</h3>
            <div className="inputWrap">
              <input
                id="signInPassword"
                name="password"
                placeholder="사이트 주소를 입력해주세요."
                onChange={inputSiteUrlHandler}
              ></input>
            </div>
            <button className="fullBtn" onClick={createScript}>
              등록하기
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {delSitePopUp ? (
        <div id="delSite" onClick={delSitePopUpHandler}>
          <div className="content" onClick={blockBubbling}>
            <div className="close" onClick={delSitePopUpHandler}>
              <svg viewBox="0 0 24 24" width="32" height="32">
                <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
              </svg>
            </div>
            <h1 className="title">사이트 삭제</h1>
            <p className="error">해당 동작은 되돌릴 수 없습니다.</p>
            <h3 className="subTitle">사이트명</h3>
            <div className="inputWrap">
              <input value={siteTitle} disabled></input>
            </div>
            <h3 className="subTitle">사이트 주소</h3>
            <div className="inputWrap">
              <input value={siteUrl} disabled></input>
            </div>
            <button className="fullBtn" onClick={deleteScript}>
              삭제하기
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default MSDetail;
